
import { Component, Vue, Emit, Prop, Watch, Mixins } from 'vue-property-decorator';
import CommonText from "@/components/common/Text.vue";
import CommonTime from "@/components/common/Time.vue";

@Component({
  components: {
    CommonText,
    CommonTime,
  }
})
export default class AllowanceForm extends Vue {
  @Prop() value;
  @Prop() error_cond: [{func, msg}];

  @Prop() init_type: number;
  @Prop() num_type: number | null;
  @Prop() unitstr: string | null;
  @Prop() per: number | null;

  once_input: boolean = false;//lazy

  get disp_value() {
    return this.value;
  }
  set disp_value(val) {
    this.input_value(val);
    this.once_input = true;
  }

  get after_label() {
    if (this.init_type == 4) {
      return '時間';
    } else {
      return this.per == 0 ? '分' : '時間';
    }
  }
  get after_label_width() {
    if (this.init_type == 4) {
      return '30px';
    } else {
      return this.per == 0 ? '15px' : '30px';
    }
  }

  @Emit('input')
  input_value(val) {
    return val == '' ? null : val;
  }
}
